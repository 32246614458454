import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/Hero'
import '../styles/TwoCols'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from "gatsby"

import SERVERS from '../images/technology/servers.svg'
import DATABASE from '../images/technology/database.svg'
import DOCKER from '../images/technology/docker.svg'
import MONITORING from '../images/technology/monitoring.svg'
import VERSIONS from '../images/technology/version-control.svg'
import OPENSOURCE from '../images/technology/open-source.svg'
import HTML5 from '../images/technology/html5.svg'



export default function TechnologyPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/technology/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='Technology' description='Overview of the amazing technology used by OneOffice' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>
        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Solid Technology</div>
              <div className='hero-slanted'>A Core Strength</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>
          <div className="heading-separator"/>
          <h1 className="title">Technology</h1>
          <h2 className="subtitle">... only the beginning</h2>
          <div className="description">
            <p>
              Something major happened about 10-15 years ago, the cloud became ubiquitous.
              This first tectonic shift meant we have other options besides legacy software running on our servers and desktops.
              The browser is the center of our world now.
            </p>
            <p>
              The second earthquake was the explosion in Open-Source software. We all realized the power of collaboration rather than competition.
              Amazon, Google, Android, Facebook, WhatsApp would not exist today if it weren't for Open Source. 
              This means you no longer pay for code, per se. You pay for service and expertise in managing solutions based on this code.
            </p>
          </div>

          <div className="twocols">
            <div className="twocols-col-image">
              <img src={SERVERS}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#1 - Servers</div>
              <div className="heading">Cost-effective Power</div>
              <div className="description">
                <p>We almost exclusively run Linux servers (Ubuntu to be exact). It requires engineers with higher skill level, but comes with tangible benefits.</p>
                <p className='quote'>Amazon, Google and Apple run almost exclusively on Linux servers. MacOS and ChromeBooks are Linux. It is the foundation of the next-generation Web.</p>
                <p>We have automated processes and tools to launch, customize and secure virtual Linux servers in the cloud. We can run 5x more workload than a traditional Microsoft Server, all from the command-line.</p>
                <p>We save on license fees (reducing your license costs) and are more efficient & secure. What's not to like.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={DATABASE}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#2 - Databases</div>
              <div className="heading">Fast & Reliable</div>
              <div className="description">
                <p>Our applications are stateless, which means each time you access the server, it loads your data. Databases are therefore a critical backbone.</p>
                <p>Most of our apps are powered by the open-source DB MySQL (MariaDB to be exact), which enables some of the most critical applications today.</p>
                <p className='quote'>MariaDB offers excellent performance and reliability. It is one of the few DBs to allow master-master replication, ensuring all application nodes are blazing fast.</p>
                <p>It also comes with easy backups and restores, an added benefit in an increasingly data-centric world.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={DOCKER}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#3 - Containers</div>
              <div className="heading">Clear Separation of Concerns</div>
              <div className="description">
                <p>Containerization is powerful (yet complex) modern technology, allowing different applications to run on the same server without interference.</p>
                <p className='quote'>We use Docker to run key applications concurrently. The Online Editor, ERP and Project Management Tool all run within Docker containers on the same server.</p>
                <p>This allows for easy updates & maintenance, especially when applications have conflicting needs and are developed by different teams. A key security benefit is the separation of applications to reduce attack vectors.</p>
              </div>
            </div>
          </div>

          <CTA variant={3}/>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={MONITORING}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#4 - Monitoring</div>
              <div className="heading">Real-time Service Health</div>
              <div className="description">
                <p>A key differentiator for us is being able to run each customer on their own servers. Before you know it, we are managing hundreds of servers.</p>
                <p className='quote'>We use the industry-leading tool Nagios to monitor each server live. We continuously monitor CPU, disk space, memory, applications, operating system, intrusion attempts ...</p>
                <p>We will know and remediate when it's time to upgrade long before you feel any degradation in service. That's a promise!</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={VERSIONS}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#5 - Version Control</div>
              <div className="heading">A Well-Oiled Machine</div>
              <div className="description">
                <p>With so many applications and team members handling mission-critical code, the ability to handle parallel development is no-longer a luxury.</p>
                <p className='quote'>We have literally over a hundred code repositories we manage. We are power users, all the way to management, of GIT for proper code management & reconciliation.</p>
                <p>Every change is documented and thoroughly tested. This is how we are able to add new features so quickly, process & tools are paramount to safe and fast execution.</p>
                <p className='text-base text-gray-600'>Sad fact: Blackberry failed because its version-control systems were lacking. Deadlines slipped and sloppy out-of-date software was shipped to clients. The rest is history.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={OPENSOURCE}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#6 - Open Source</div>
              <div className="heading">Power in Collaboration</div>
              <div className="description">
                <p>The cost of good development is absolutely monstrous (ask us, we know!). There is no glory in re-inventing the wheel when others have solved the problem elegantly. Not to mention customers have to absorb the cost of this inefficiency</p>
                <p className='quote'>On a daily-basis you use more open-source than you think. It powers your apps and web everywhere! In fact, the browser you are using now is open-source.</p>
                <p>However, the skill-level required to handle so many different open-source tools is staggering. We have processes & tools in place to customize & secure each new version of an application. We have contributed countless customizations, improvements & integrations.</p>
                <p className='text-base text-gray-600'>Fun fact: This super-fast website was developed using Facebook's React framework, Tailwind (styling) and NodeJS (Google Chrome).</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={HTML5}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#7 - Modern Tech</div>
              <div className="heading">Not your father's software</div>
              <div className="description">
                <p>Technology has, thankfully, evolved since the dark days of Internet Explorer 6 and 7. Browsers now are fully-functionl virtual machines in their own right. Mobile apps have more power than laptops from 10 years ago.</p>
                <p className='quote'>OneOffice runs as an application within your desktop browser. Smart caching and optimizations use less bandwidth and run 2-4x faster than the next best solution.</p>
                <p>We painstaikingly test our apps on all sorts of devices, to ensure your user experience is fully optimized.</p>
              </div>
            </div>
          </div>

          <CTA/>
        </div>
      </main>
    </Layout>
  )
}
